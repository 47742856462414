.clearfix:before,
.clearfix:after {content:" ";display:table;}
.clearfix:after {clear:both;}
.clearfix {*zoom:1;}
/*==================================================
 * Item list
 * ===============================================*/

.lock_description {background-color:#fff;padding:24px 30px;}

nav.filter {
    margin-bottom:24px;
    .form-row {
        float:right;
        label {width:auto;padding-right:20px;}
        select {width:auto;}
    }
}

/*==================================================
 * Moderatie tool
 * ===============================================*/

.moderate_type {color:#f15a3b;font-size:16px;}

.news.moderate, .events.moderate, .albums.moderate, .documents.moderate {
    article {
        height:auto;
        .content {
            height:auto;
        }
    }
}

.orange {color:#f15a3b;font-weight:bold;}

.trashed,
.moderation-buttons {
    clear:both;border-top:1px solid #d4d4d4;padding-top:5px;color:#f15a3b;font-weight:bold;font-size:14px;
    * {padding:0 10px;border-left:1px solid #d4d4d4;}
    *:first-child {padding-left:0;border-left:0;}
}

.button, button {
    &.approve {background-color:#5cb85c;border-color:#3c763d;}
    &.deny {background-color:#d9534f;border-color:#a94442;}
}

form {
    &.edit-form {
        .form-row {
            input[disabled],
            textarea[disabled],
            select[disabled] {color:graytext;border:1px solid #d4d4d4;}
            &.form-row-recurring, &.form-row-recurring-ondays {
                .label {display:inline;padding:0 10px;}
                .label:first-child {padding-left:0;}
                .label.float {float:left;}
                select {display:inline;float:none;width:auto;}
                select[multiple] {width:200px;}
                .ms-parent {
                    display:inline-block;float:none;
                }
                .ms-drop .ms-select-all {margin-bottom:15px;padding-bottom:20px;}
                .recurring_until {
                    display:block;padding-left:59px;
                    label, 
                    input {
                        display:inline-block;float:none;width:auto;
                        &[type="number"] {width:60px;}
                    }
                    label em {
                        display:block;white-space:normal;font-size:12px;line-height:18px;font-weight:normal;font-style:normal;
                    }
                }
            }
        }
    }
    .form-row .select-and-go {
        select {width:calc(100% - 70px)}
        button {float:right;height:31px;line-height:31px;padding:0 20px;}
    }
}

/*==================================================
 * Edit profile
 * ===============================================*/
.edit-opvang {display:none;}

.calendar-icon {
    background:url(/img/kalender-afgemeld.png) no-repeat center center;width:250px;height:250px;display:block;color:#000;margin:0 auto 23px;opacity:0.5;
    &.subscribed {background-image:url(/img/kalender-aangemeld.png);opacity:1}
    &.unsubscribed {background-image:url(/img/kalender-afgemeld.png);opacity:1}
    > * {display:block;text-align:center;}
    .month {padding-top:54px;font-size:34px;line-height:44px;color:#fff;}
    .dayofweek {
        font-size:48px;line-height:53px;color:#333;padding-top:20px;
        .day {font-size:50px;color:#000;}
    }
    .state {padding-top:13px;font-size:29px;line-height:29px;font-weight:bold;text-transform:lowercase;}
}

.list-items:after {content:", "}
.list-items:last-child:after {content:""}

.kindgegevens textarea {height:99px;}

/*==================================================
 * Albums
 * ===============================================*/

ol.album {
    list-style:none;padding:0;margin:0;
    li {display:inline-block}
    li a {display:block;}
    li a img {display:block;width:100px;height:100px;border:0;}
}

table.album-media {
    margin-bottom:0;width:100%;
    tr {
        border-bottom:0;
    }
    .preview {
        width:150px;padding-left:0;position:relative;
        img {display:block;width:150px;height:150px;}
        img, canvas {transition:transform 0.2s ease-out;}
        a.rotate,
        a.remove {
            position:absolute;top:10px;left:128px;display:block;font-size:20px;
            .fa {color:#fff!important;-webkit-filter:drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.5));filter:drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.5));}
        }
        a.rotate {left:10px;}
    }
    &.no-description {
        .preview {padding-left:226px;}
        .remove {width:calc(100% - 326px);padding-left:20px;}
    }
    input[type="text"] {width:100%!important;}
}

/*==================================================
 * Agenda
 * ===============================================*/

.events {
    article {
        height:auto;
        .content {
            height:auto;min-height:158px;
            .overflow {
                height:auto;
                ul {
                    font-size:12px;padding:0 0 0 20px;margin-bottom:23px;
                }
                p+ul {margin-top:-5px;}
            }
        }
    }
}

/*==================================================
 * Users
 * ===============================================*/
table.userinfo caption {font-weight:bold;font-size:18px;margin-bottom:10px;text-align:left;}
table.userinfo th {width:30%;}
table.userinfo td {width:70%;}

/*==================================================
 * Invoices / payments
 * ===============================================*/
form.filter {
    @extend .clearfix;padding-left:11px;
    label {float:left;display:block;line-height:40px;margin-right:10px;}
    select {float:left;display:block;width:200px;height:40px;padding:0 5px;box-sizing:border-box;}
}

@media only screen and (max-width:767px) {
    form.filter {
        label {width:50%;margin-right:0;}
        select {width:50%;}
    }
}

/*==================================================
 * Documents
 * ===============================================*/

section.documents {
    article {
        .preview {
            position:absolute;top:0;left:0;width:180px;height:180px;text-align:center;line-height:180px;
            .fa {font-size:50px;}
        }
    }
}
table.documents {
    .download-button {width:150px;}
}

/*==================================================
 * Aan / afmelden
 * ===============================================*/

.timer {font-size:50px;color:#333;}

/*==================================================
 * Multi file upload
 * ===============================================*/

.button-fileinput {
    position:relative;overflow:hidden;display:inline-block;cursor:pointer;
    input {position:absolute;top:0;right:0;margin:0;opacity:0;-ms-filter:'alpha(opacity=0)';font-size:200px!important;direction:ltr;cursor:pointer;float:none!important;padding:0!important;width:auto!important;}
}

@media screen\9 {
  .button-fileinput input {filter: alpha(opacity=0);font-size: 100%;height: 100%;}
}

#fileupload {
    clear:both;
    &.single {
        @extend .clearfix;
        .upload-preview {width:150px;float:left;}
        .upload-meta {
            float:left;margin-left:20px;font-size:14px;line-height:20px;width:calc(100% - 170px);white-space:nowrap;
            .button {float:none;margin-left:5px;}
            .button-fileinput {top:7px;}
        }
    }
}

.upload-preview {
    margin-bottom:0;
}
#preview-container {
    .loading {width:150px;height:150px;}
    .thumbnail-container{
        position:relative;
        img,
        canvas {transition:transform 0.2s ease-out;}
        .rotate,
        .remove {
            position:absolute;top:5px;left:122px;font-size:20px;
            .fa {color: #f05a3b;}
        }
        .rotate {left:10px; font-size: 30px;}
        .thumbnail {
            width:150px;display:block;
            &:empty {height:150px;background:url('/img/no-photo.png');background-size:150px 150px;}
            img,
            video,
            audio {width:150px;height:auto;}
            .fa {width:150px;text-align:center;}
        }
        .filename {display:none;}
    }
    td.thumbnail-container {
        width:150px;
        .remove {top:10px;right:20px;}
        .rotate {top:10px;left:20px;}
    }
    tr.error {background-color:#f9f2f4;color:#c7254e;}
    a, a span {color:#000;}
}

.lt-ie10 #preview-container .thumbnail-container {
    .thumbnail {display:none;}
    .filename {display:inline-block;}
    .remove {
        position:static;display:inline-block;
        .fa {color:#000;}
    }
}

.progress {
    width:100%;height:20px;position:relative;margin-bottom:20px;background-color:#d4d4d4;
    .bar {position:absolute;top:0;left:0;width:0%;height:100%;background-color:#f15a3b;display:block;transition:width 0.2s ease-out}
    .label {width:100%;text-align:center;position:relative;line-height:20px;font-size:14px;display:block;}
}

